<template lang="pug">
  .ota-accounts
    Tabs(
      :accounts="[...items, ...itemsAdded]"
      :imports-progress="importsProgress"
      @set-account="setAccount"
      @add-new="addItem"
      @delete-account="deleteAccount"
      @save-account="saveAccount"
      @deactivate-account="deactivateAccount"
    )
</template>

<script>
  // store modules
  import otaAccountsModule from "@/config/store/ota_accounts"

  // mixins
  import withContactOtaNotification from "@/mixins/withContactOtaNotification"
  import withWebSocket from "@/mixins/withWebSocket"
  import withStoreModule from "@/mixins/withStoreModule"

  // misc
  import { showToast } from "@/helpers/toasts"

  const otaAccountsStoreMixin = withStoreModule(otaAccountsModule, {
    name: "otaAccounts",
    readers: ["items", "itemsAdded", "importsProgress"],
    mutations: { setImportsProgress: "SET_IMPORTS_PROGRESS" },
    actions: {
      addItem: "ADD_ITEM",
      setItem: "SET_ITEM",
      setItemLoading: "SET_ITEM_LOADING",
      setAddedItem: "SET_ADDED_ITEM",
      deleteAddedItem: "DELETE_ADDED_ITEM",
      fetchOtaAccounts: "FETCH_ITEMS",
      deleteItem: "DELETE_ITEM",
      createItem: "CREATE_ITEM",
      updateItem: "UPDATE_ITEM"
    }
  })

  export default {
    components: {
      Tabs: () => import("@/pages/OtaAccounts/Tabs")
    },

    mixins: [otaAccountsStoreMixin, withWebSocket, withContactOtaNotification],

    mounted() {
      const { fetchOtaAccounts, setImportsProgress, webSocketSubscribe } = this

      webSocketSubscribe("TrackingImportChannel", {
        connected: () => fetchOtaAccounts({ track_get_data_from_ota: true }),
        received: setImportsProgress
      })

      if (this.useContactOtaNotification) {
        webSocketSubscribe("ImportCreatedChannel", { received: () => this.showContactOtaNotification() })
      }
    },

    methods: {
      setAccount(account) {
        account._addedAt ? this.setAddedItem(account) : this.setItem(account)
      },

      saveAccount(account) {
        account.id ? this.updateAccount(account) : this.createAccount(account)
      },

      updateAccount(account) {
        this.setItemLoading({ itemId: account.id, loading: true })
        this.updateItem(this.prepareData(account)).finally(() => {
          this.setItemLoading({ itemId: account.id, loading: false })
        })
      },

      createAccount(account) {
        this.createItem(this.prepareData(account))
          .then(() => {
            this.deleteAddedItem(account)
          })
          .catch(({ response }) => {
            showToast({ text: response.data.record.ota_id })
          })
      },

      prepareData({ id, username, password, active, ota }) {
        return {
          id,
          ota_account: {
            username,
            password,
            active,
            ota_id: ota.id
          }
        }
      },

      deactivateAccount({ id }) {
        this.updateItem({ id, ota_account: { active: false } })
      },

      deleteAccount(account) {
        account.id ? this.deleteItem(account.id) : this.deleteAddedItem(account)
      }
    }
  }
</script>

<style lang="sass" scoped>
  .ota-accounts
    position: relative
</style>
