import { mapGetters } from "vuex"
import withNotification from "@/mixins/withNotification"

/**
 * Temporary solution according the task
 * https://www.notion.so/59a8d653a9fd458bb4074433565394ca?v=3951ae49b4ac41668a94e53f166fceda&p=35cd15cf16684a249612d5f4c9415467
 */
export default {
  mixins: [withNotification],

  computed: {
    ...mapGetters(["isOrixOrganization", "isToyotaOrganization"]),

    useContactOtaNotification() {
      return this.isOrixOrganization || this.isToyotaOrganization
    }
  },

  methods: {
    showContactOtaNotification(handler) {
      this.$notify({ title: this.$t("alerts.contact_ota"), handler })
    }
  }
}
