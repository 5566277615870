import { bus } from "@/config"

/**
 *  Notification dialog
 *
 *  @param {object}
 *    - title {String} notification title
 *    - handler {Function} handler when user clicks OK
 *
 *  @return {undefined}
 *
 *  @example
 *    this.$notify({
 *      title: "Are you sure to delete this user?",
 *      resolve: {
 *        handler: () => user.delete(),
 *        titleAfter: "User successfully deleted"
 *      }
 *    })
 */
export default {
  methods: {
    $notify({ title, handler }) {
      bus.emit("open-notify-dialog", { title, handler })
    }
  }
}
